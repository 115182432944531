import StateSelect from "./StateSelect";

export default class Address {
	
	constructor () {
		this.addresses = document.querySelectorAll("select[name*='AddressId']");
		this.states    = document.querySelectorAll("select[name*='[stateId]']");
		
		this.initAddresses();
		this.initStates();
	}
	
	// Addresses
	// =========================================================================
	
	initAddresses () {
		let i = this.addresses.length;
		while (i--) {
			const address = this.addresses[i];
			
			address.handle = address.name.replace("Id", "");
			address.fields = Address.getAllFieldsForAddress(address.handle);
			address.countryField = document.querySelector(`[name='${address.handle}[countryId]']`);
			address.getAddress = () => {
				const selected = address.options[address.selectedIndex];
				return JSON.parse(selected.dataset.address);
			};
			
			address.addEventListener(
				"change",
				this.onAddressIdChange.bind(this, address)
			);
			
			this.onAddressIdChange(address);
		}
	}
	
	// Addresses: Events
	// -------------------------------------------------------------------------
	
	onAddressIdChange = address => {
		if (address.value === "") {
			address.fields.forEach(field => {
				field.removeAttribute("disabled");
				field.value = field.name.indexOf("countryId") > -1 ? 77 : "";
				field.isRequired && field.setAttribute("required", "required");
			});
			
			StateSelect.updateSelects();
		} else {
			const nextAddress = address.getAddress();
			
			address.countryField.value = nextAddress["countryId"];
			
			StateSelect.updateSelects();
			
			address.fields.forEach(field => {
				const name = field.name.replace(`${address.handle}[`, "").replace("]", "");
				
				field.value = nextAddress[name];
				field.setAttribute("disabled", "disabled");
				field.removeAttribute("required");
			});
		}
	};
	
	// Addresses: Helpers
	// -------------------------------------------------------------------------
	
	static getAllFieldsForAddress (handle) {
		const all    = document.querySelectorAll(`[name*='${handle}']`)
			, fields = [];
		
		let i = all.length;
		while (i--) {
			const field = all[i];
			
			if (
				!field.nodeName.toLowerCase().match(/^(input|select|textarea)$/)
				|| field.name === `${handle}Id`
			) continue;
			
			field.isRequired = field.hasAttribute("required");
			
			fields.push(field);
		}
		
		return fields;
	}
	
	// States
	// =========================================================================
	
	initStates () {
		let i = this.states.length;
		while (i--) {
			new StateSelect(
				this.states[i].getAttribute("id").replace("State", "")
			);
		}
	}
	
}
