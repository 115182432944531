import API from "./API";

export default class Shop {
	
	constructor () {
		this.initLoadMore();
	}
	
	// Load More
	// =========================================================================
	
	isLoadingMore = false;
	loadStopper = 0;
	io = null;
	
	initLoadMore () {
		this.loadMore = document.getElementById("loadMore");
		
		if (!this.loadMore) return;
		this.productsContainer = document.getElementById("productsContainer");
		this.loadMore.addEventListener("click", this.onLoadMoreClick);
	}
	
	initLoadMoreWatcher () {
		this.io = new IntersectionObserver((entries) => {
			if (
				!this.isLoadingMore
				&& entries[0].isIntersecting
				&& this.loadStopper !== 3
			) {
				this.loadStopper++;
				this.onLoadMoreClick();
			}
		});
		
		this.io.observe(this.loadMore);
	}
	
	onLoadMoreClick = e => {
		e && e.preventDefault();
		e && (this.loadStopper = 0);
		if (this.io === null) this.initLoadMoreWatcher();
		
		this.loadMore.classList.add("loading");
		this.isLoadingMore = true;
		API.GET(this.loadMore.getAttribute("href")).then(({ data, headers }) => {
			this.addLoadedCards(data);
			this.isLoadingMore = false;
			
			if (headers.xPaginationNextUrl) {
				this.loadMore.classList.remove("loading");
				this.loadMore.setAttribute("href", headers.xPaginationNextUrl);
			} else {
				this.io.disconnect();
				this.loadMore.style.display = "none";
			}
		});
	};
	
	addLoadedCards (newCardsHTMLString) {
		const temp = document.createElement("div");
		temp.style.display = "none";
		temp.innerHTML = newCardsHTMLString;
		document.body.appendChild(temp);
		
		let i = 0;
		while (temp.firstElementChild) {
			const card = temp.firstElementChild;
			card.classList.add("card-out");
			this.productsContainer.appendChild(card);
			setTimeout(() => {
				card.classList.remove("card-out");
			}, i * 50);
			i++;
		}
	}
	
}
