import Cart from "./Cart";
import { post } from "./Helpers";

export default class Product {

	constructor () {
		this.addToCart = document.getElementById("addToCart");
		this.variantDropdown = document.getElementById("purchasableId");
		this.images = document.getElementById("productImages").children;

		this.initAddToCart();

		this.variantDropdown.addEventListener("change", this.onVariantSwitch);
	}

	// Initializers
	// =========================================================================

	initAddToCart () {
		this.addToCart.addEventListener("click", () => {
			if (this.addToCart.getAttribute("disabled")) return;

			Cart.open(true);

			post("commerce/cart/update-cart", {
				"qty": 1,
				"purchasableId": +this.variantDropdown.value,
			}).then(() => {
				Cart.update();
			});
		});
	}

	// Events
	// =========================================================================

	onVariantSwitch = () => {
		let i = this.images.length;
		while (i--) this.images[i].classList.remove("active");

		i = this.variantDropdown.options[
			this.variantDropdown.selectedIndex
		].dataset.index|0;

		this.images[i - 1].classList.add("active");
	};

}
