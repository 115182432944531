import { FocusManager } from "./Helpers";

/**
 * Modals
 * ======
 * For use with _embed/modal.twig
 */
export default class Modal {

	constructor () {
		// Modals
		this.modals = Array.prototype.reduce.call(
			document.querySelectorAll("[data-modal]"),
			(a, b) => {
				const handle = b.dataset.modal;
				if (Object.keys(a).indexOf(handle) > -1) {
					// eslint-disable-next-line
					console.error(`The modal "${handle}" already exists!`);
					return a;
				}

				b.show = () => { this.openModal(handle); };
				b.hide = () => { this.closeModal(handle); };

				a[handle] = b;
				b.addEventListener(
					"click",
					this.onModalOverlayClick.bind(this, b)
				);
				return a;
			},
			{}
		);

		// Openers
		[].slice.call(
			document.querySelectorAll("[data-open-modal]")
		).forEach(trigger => {
			const handle = trigger.dataset.openModal;
			trigger.addEventListener("click", e => {
				e.preventDefault();
				this.openModal(handle, trigger);
			});
		});

		// Hash Open
		window.addEventListener("hashchange", this.onHashChange);
		this.onHashChange();

		// Closers
		[].slice.call(
			document.querySelectorAll("[data-close-modal]")
		).forEach(trigger => {
			trigger.addEventListener("click", e => {
				e.preventDefault();
				this.closeModal();
			});
		});

		// Esc Close
		document.addEventListener("keydown", e => {
			if (e.keyCode === 27) this.closeModal();
		});
	}

	activeModal = null;

	_beforeOpenEvents = [];

	// Events
	// =========================================================================

	onHashChange = () => {
		const hash = window.location.hash.replace("#", "");

		if (hash === "" || hash === 'cart')
			return;

		history.replaceState(
			"",
			document.title,
			window.location.pathname + window.location.search
		);

		this.openModal(hash);
	};

	onModalOverlayClick = (elem, e) => {
		if (e.target !== elem) return;

		this.closeModal();
	};

	// Actions
	// =========================================================================

	openModal (handle, trigger = null) {
		if (!this.modals.hasOwnProperty(handle))
			return;

		const modal = this.modals[handle];

		this._beforeOpenEvents.forEach(func => {
			func(modal, trigger);
		});

		this.closeModal();

		this.activeModal = modal;
		modal.classList.add("visible");
		modal.removeAttribute("aria-hidden");

		document.body.classList.add("modal-open");

		FocusManager.setup(modal);
	}

	closeModal () {
		FocusManager.destroy();

		if (!this.activeModal) return;

		this.activeModal.setAttribute("aria-hidden", "true");
		this.activeModal.classList.remove("visible");
		this.activeModal = null;

		document.body.classList.remove("modal-open");
	}

	// Public
	// =========================================================================

	onBeforeOpen (func) {
		this._beforeOpenEvents.push(func);
	}

}
