import "babel-polyfill";

import Site from "./Site";
import Modal from "./Modal";
import Cart from "./Cart";

import Sport from "./Sport";
import Shop from "./Shop";
import Product from "./Product";
import Checkout from "./Checkout";

Site.sniff();

function onResourceLoad () {
	window.removeEventListener("DOMContentLoaded", onResourceLoad);

	new Site();
	window.modal = new Modal();

	if (window.p !== "checkout")
		window.cart = new Cart();

	switch (window.p) {
		case "shop":
			new Shop();
			break;
		case "product":
			new Product();
			break;
		case "checkout":
			new Checkout();
			break;
		case "sport":
			new Sport();
			break;
	}
}

window.addEventListener('DOMContentLoaded', onResourceLoad);
