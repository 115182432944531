import { get, post } from "./Helpers";

export default class Cart {

	constructor () {
		this.openCart = document.getElementById("openCart");
		this.closeCart = document.getElementById("closeCart");
		this.overlay = document.getElementById("cartOverlay");

		this.cart = document.getElementById("cart");
		this.badge = document.getElementById("cartBadge");

		this.initEvents();
		this.parseQtys();

		if (window.location.hash === "#cart") {
			history.replaceState(
				"",
				document.title,
				window.location.pathname + window.location.search
			);

			Cart.open();
		}
	}

	// Initializers
	// =========================================================================

	initEvents () {
		this.openCart && this.openCart.addEventListener("click", e => {
			e.preventDefault();
			Cart.open();
		});

		this.closeCart && this.closeCart.addEventListener("click", e => {
			e.preventDefault();
			Cart.close();
		});

		this.overlay && this.overlay.addEventListener("click", () => {
			Cart.close();
		});

		this.overlay && document.addEventListener("keydown", e => {
			if (e.keyCode === 27) Cart.close();
		});
	}

	// Update Cart
	// =========================================================================

	update () {
		this.cart.classList.add("working");

		get(window.baseUrl + "cart").then(nextCart => {
			this.cart.innerHTML = nextCart;
			this.cart.classList.remove("working");
			this.updateCartInfo();
			this.parseQtys();
		});
	}

	updateCartInfo () {
		const info = document.getElementById("cartInfo").dataset;

		// Cart total qty badge
		this.badge.textContent = info.qty;
	}

	parseQtys () {
		const selects = [].slice.call(this.cart.getElementsByTagName("select"));

		selects.forEach(select => {
			select.addEventListener("change", this.onQtyChange);
		});
	}

	onQtyChange = e => {
		const id  = e.target.dataset.id
			, qty = e.target.value;

		this.cart.classList.add("working");

		post("commerce/cart/update-cart", {
			[`lineItems[${id}][qty]`]: qty,
		}).then(() => {
			this.update();
		});
	};

	// Public
	// =========================================================================

	static open (working = false) {
		working && window.cart.cart.classList.add("working");
		document.body.classList.add("cart-open");
	}

	static close () {
		document.body.classList.remove("cart-open");
	}

	static update () {
		window.cart.update();
	}

}
