export default class StateSelect {
	
	prefixMap = {
		"e": "England",
		"i": "Northern Ireland",
		"s": "Scotland",
		"w": "Wales",
	};
	
	static _selects = [];
	
	constructor (modelName) {
		if (window.states && Array.isArray(window.states)) {
			window.states = window.states.reduce(function (a, b) {
				const doGroup = b.abbreviation.indexOf('-') > -1;
				
				if (!a.hasOwnProperty(b.countryId))
					a[b.countryId] = doGroup ? {} : [];
				
				if (doGroup) {
					const prefix = b.abbreviation.split('-')[0];
					
					if (!a[b.countryId].hasOwnProperty(prefix))
						a[b.countryId][prefix] = [];
					
					a[b.countryId][prefix].push(b);
				} else {
					a[b.countryId].push(b);
				}
				
				return a;
			}, {});
		}
		
		this.countrySelect = document.getElementById(modelName + "Country");
		this.stateSelect   = document.getElementById(modelName + "State");
		this.selectedState = document.getElementById(modelName + "SelectedState").value;
		
		this.countrySelect.update = () => {
			this.populateStateSelect(this.countrySelect.value);
		};
		
		if (
			this.countrySelect.value
			&& !this.countrySelect.hasAttribute("disabled")
		) this.countrySelect.update();
		
		this.countrySelect.addEventListener("change", () => {
			this.countrySelect.update();
		});
		
		StateSelect._selects.push(this.countrySelect);
	}
	
	// Actions
	// =========================================================================
	
	populateStateSelect (countryId) {
		while (this.stateSelect.firstElementChild)
			this.stateSelect.removeChild(this.stateSelect.firstElementChild);
		
		const newOptions = window.states[countryId];
		
		if (!newOptions) {
			this.stateSelect.setAttribute("disabled", "disabled");
			this.stateSelect.removeAttribute("required");
		} else {
			this.stateSelect.removeAttribute("disabled");
			this.stateSelect.setAttribute("required", "required");
		}
		
		const placeholder = document.createElement("option");
		placeholder.textContent = newOptions ? "Please select..." : "Not required";
		placeholder.setAttribute("selected", "selected");
		placeholder.setAttribute("hidden", "hidden");
		placeholder.setAttribute("disabled", "disabled");
		this.stateSelect.appendChild(placeholder);
		
		if (!newOptions) return;
		
		if (Array.isArray(newOptions)) {
			newOptions.map(opt => {
				this.createOption(opt, this.stateSelect);
			});
		} else {
			Object.keys(newOptions).sort().map(prefix => {
				const og = document.createElement("optgroup");
				og.setAttribute("label", this.prefixMap[prefix]);
				
				newOptions[prefix].map(opt => {
					this.createOption(opt, og);
				});
				
				this.stateSelect.appendChild(og);
			});
		}
	}
	
	static updateSelects () {
		this._selects.forEach(select => {
			select.update();
		});
	}
	
	// Helpers
	// =========================================================================
	
	createOption (opt, target) {
		const o = document.createElement("option");
		o.textContent = opt.name;
		o.value = opt.id;
		if (opt.id === this.selectedState)
			o.setAttribute("selected", "selected");
		target.appendChild(o);
	}
	
}
