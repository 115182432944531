import _focusManager from "./helpers/FocusManager";
export { _focusManager as FocusManager };

/**
 * Converts snake-case strings to camelCase
 *
 * ```js
 * StringHelpers.snakeToCamel("my-snake-string");
 * // => "mySnakeString"
 * ```
 *
 * @param {string} s - The snake-case string
 * @returns {string} - The converted camelCase string
 */
export function snakeToCamel(s){
	let r = s.replace(/(-\w)/g, m => m[1].toUpperCase());
	return r[0].toLowerCase() + r.slice(1);
}

/**
 * Performs a POST request
 *
 * @param action
 * @param body
 * @returns {Promise}
 */
export function post (action, body) {
	const formData = new FormData();

	formData.append(window.csrf[0], window.csrf[1]);
	Object.keys(body).forEach(name => {
		formData.append(name, body[name]);
	});

	return fetch('/actions/' + action.replace(/^\//, ''), {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'X-Requested-With': 'XMLHttpRequest',
		},
		body: formData,
	}).then(res => res.json());
}

/**
 * Performs a GET request
 *
 * @param url
 * @returns {Promise}
 */
export function get (url) {
	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		xhr.open("GET", url, true);
		xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

		xhr.onload = () => {
			let data = xhr.responseText;

			try {
				data = JSON.parse(data);
			} catch (e) { /**/ }

			if (xhr.status >= 200 && xhr.status < 400) {
				resolve(data);
			} else {
				reject(data);
			}
		};

		xhr.onerror = () => reject(xhr.statusText);

		xhr.send();
	});
}

/**
 * ## Create Element
 * Quick and easy DOM element creation
 *
 * @param {string=} tag - The element tag
 * @param {object=} attributes - The attributes to add, mapping the key as
 *     the attribute name, and the value as its value. If the value is a
 *     function, it will be added as an event.
 * @param {(Array|*)=} children - An array of children (can be a mixture of
 *     Nodes to append, or other values to be stringified and appended
 *     as text).
 * @return {Element} - The created element
 */
export function t (
	tag = "div",
	attributes = {},
	children = []
) {
	const elem = document.createElement(tag);

	for (let [key, value] of Object.entries(attributes)) {
		if (!value) continue;

		if (typeof value === typeof (() => {})) {
			if (key === "ref") value(elem);
			else elem.addEventListener(key, value);
			continue;
		}

		if (key === "style")
			value = value.replace(/[\t\r\n]/g, " ").trim();

		elem.setAttribute(key, value);
	}

	if (!Array.isArray(children))
		children = [children];

	children.map(child => {
		if (!child) return;

		try {
			elem.appendChild(child);
		} catch (_) {
			elem.appendChild(document.createTextNode(child));
		}
	});

	return elem;
}
