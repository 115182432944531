import Address from "./Address";

export default class Checkout {

	constructor () {
		new Address();

		this.initAccordion();
	}

	// Accordions
	// =========================================================================

	initAccordion () {
		const accordions = [].slice.call(
			document.getElementsByClassName("checkout--accordion")
		);

		if (!accordions.length)
			return;

		accordions.forEach(accordion => {
			accordion.radios = [].slice.call(
				accordion.querySelectorAll("label input[type=radio]")
			);

			[].slice.call(accordion.querySelectorAll(".visible")).forEach(v => {
				v.style.maxHeight =
					v.firstElementChild.getBoundingClientRect().height + "px";
			});

			accordion.radios.forEach(radio => {
				radio.addEventListener(
					"change",
					this.onAccordionChange.bind(this, accordion)
				);
			});

			accordion.panels = [].slice.call(accordion.children).map(panel => {
				if (panel.nodeName !== "DIV")
					return;

				panel.allInputs = [...panel.querySelectorAll('input, textarea, select')];

				panel.requiredElems = [].slice.call(
					panel.querySelectorAll("[required]")
				);

				if (!panel.classList.contains(".visible")) {
					panel.requiredElems.forEach(el => {
						el.removeAttribute("required");
					});
				}

				return panel;
			});
		});
	}

	onAccordionChange = accordion => {
		accordion.radios.forEach(radio => {
			const label = radio.parentNode.parentNode;
			const div = label.nextElementSibling;
			const d = div && div.nodeName === "DIV";
			if (radio.checked) {
				label.classList.add("active");
				if (d) {
					div.style.maxHeight =
						div.firstElementChild.getBoundingClientRect().height
						+ "px";

					div.allInputs.forEach(el => {
						el.removeAttribute('disabled');
					});

					div.requiredElems.forEach(el => {
						el.setAttribute("required", "required");
					});
				}
			} else {
				label.classList.remove("active");
				if (d) {
					div.style.maxHeight = "0px";

					div.allInputs.forEach(el => {
						el.setAttribute('disabled', 'true');
					});

					div.requiredElems.forEach(el => {
						el.removeAttribute("required");
					});
				}
			}
		});
	};

}
