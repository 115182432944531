import { snakeToCamel } from "./Helpers";

const APIRequests = {};

const _API_HEADERS = [
	'Link',
	'X-Pagination-Current-Page',
	'X-Pagination-Page-Count',
	'X-Pagination-Per-Page',
	'X-Pagination-Total-Count',
	'X-Pagination-Next-Url',
	'User-Flash',
];

/**
 * API Caller
 *
 * @param {"GET"|"POST"} method - The request method
 * @param {string} endPoint - The endpoint to request
 * @param {object} params - An object of `?key=value` pair URL parameters
 * @param {object|FormData} body - An object of `fieldName = fieldValue` pairs that are
 *      converted to form data.
 * @param {boolean} ignoreParams - If true, only the endpoint will be used to
 *      judge whether the request should be cancelled, false (default) will use
 *      the full URL.
 *
 * @return {Promise}
 */
function API (
	method = "GET",
	endPoint = "",
	params = {},
	body = {},
	ignoreParams = false
) {
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		
		// Params
		// -----------------------------------------------------------------
		params = Object.keys(params).map(paramName => (
			`${paramName}=${params[paramName]}`
		)).join("&");
		
		if (params)
			params = "?" + params;
		
		const url = window.baseUrl + endPoint + params;
		
		// Body
		// -----------------------------------------------------------------
		let formData = null;
		
		if (body instanceof FormData) {
			formData = body;
			body = {isFormData:true};
		} else {
			formData = Object.keys(body).reduce((fd, k) => {
				fd.append(k, body[k]);
				return fd;
			}, new FormData());
		}
		
		// Request
		// -----------------------------------------------------------------
		
		const requestKey = ignoreParams ? endPoint : url;
		if (APIRequests.hasOwnProperty(requestKey) &&
		    typeof APIRequests[requestKey].cancel === typeof (() => {}))
			APIRequests[requestKey].cancel();
		
		xhr.open(method, url, true);
		xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
		xhr.onload = function () {
			if (xhr.status >= 200 && xhr.status < 400) {
				let data, headers = {};
				
				try {
					data = JSON.parse(xhr.responseText);
				} catch (e) {
					data = xhr.responseText;
				}
				
				_API_HEADERS.map(header => {
					try {
						headers[snakeToCamel(header)] =
							xhr.getResponseHeader(header);
					} catch (e) { /* No need to shout about it */ }
				});
				
				if (
					(data.hasOwnProperty("errors") && data.errors.length)
					|| (data.hasOwnProperty("success") && !data.success)
				) {
					reject(data.errors);
				} else {
					resolve({ data, headers });
				}
			} else {
				let data;
				
				try {
					data = JSON.parse(xhr.responseText);
				} catch (e) {
					data = xhr.responseText;
				}
				
				reject(data);
			}
		};
		xhr.onerror = function () {
			reject(xhr.responseText);
		};
		xhr.send(Object.keys(body).length ? formData : null);
		
		APIRequests[url] = xhr;
	});
}

API.GET = function (endPoint = "", params = {}, ignoreParams = false) {
	return API("GET", endPoint, params, {}, ignoreParams);
};

API.POST = function (endPoint = "", body = {}, params = {}) {
	return API("POST", endPoint, params, body);
};

export default API;
